<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="canEditGoals"
            variant="primary"
            :disabled="isAddRecordActive"
            @click="openGoalsModel()"
          >
            <span>{{ $t('ADD_GOAL') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="competition"
              :options="competitions"
              label="c_string_swe"
              :clearable="false"
              class="flex-grow-1 ml-50 mr-1"
              :placeholder="$t('SELECT_CONTENT')"
              @input="changeCompetitionClick()"
            />
          </div>

        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refGoalsListTable"
      responsive
      :fields="goalsTableColumns"
      :items="goals"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: description -->
      <template #cell(Description)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :variant="`light-${
                resolveUserStatusVariant(data.item.enabled).variant
              }`"
              :text="avatarText(data.item.description.substring(0,1))"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="openGoalsModel(data.item)"
          >
            {{ strSubstring(data.item.description,15) }}
          </b-link>
          <small class="text-muted">{{ resolveGoals(data.item.src) }}</small>
        </b-media>
      </template>

      <!-- Column:floatvalue -->
      <template #cell(Source)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ resolveGoals(data.item.src) }}
          </span>
        </b-media>
      </template>

      <!-- Column:floatvalue -->
      <template #cell(Value)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.floatvalue }}
          </span>
        </b-media>
      </template>
      <!-- Column:GoalType -->
      <template #cell(GoalType)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.goal_type }}
          </span>
        </b-media>
      </template>
      <!-- Column:time_period -->
      <template #cell(TimePeriod)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.time_period }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            v-if="canEditGoals"
            :id="`game-row-${data.item.ID}-send-icon`"
            v-b-modal.goals-modal
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="openGoalsModel(data.item)"
          />

          <feather-icon
            :id="`game-row-${data.item.ID}-preview-icon`"
            v-b-modal.goals-modal
            icon="AwardIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'game-view', params: { id: competition.encrypt_id }})"
          />

          <feather-icon
            v-if="canEditGoals"
            :id="`game-row-${data.item.ID}-send-icon`"
            icon="Trash2Icon"
            class="cursor-pointer mr-1"
            size="16"
            @click="removeGoals(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalGoals"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>
    <goals-model
      :fetch-goals="fetchGoals"
      :competition="competition"
      :edit-goals="editGoals"
      :behaviours="behaviours"
      :habits="habits"
      :kpis="kpis"
    />
  </b-card>
</template>

<script>
import {
  BCard, BLink,
  BRow, BCol, BButton, BTable,
  BMedia,
  BPagination, BAvatar, VBPopover,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import constants from '@/constants/static.json'
import gameStore from '@/store/game/gameStore'
import { mixinList } from '@/constants/mixinValidations'
import { mixinDate } from '@/constants/mixinDate'
import mixinAlert from '@/constants/mixinAlert'
import router from '@/router'
import { canEditDeleteGame, resolveUserStatusVariant } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import GoalsModel from './GoalsModel.vue'
import useGoalsList from './useGoalsList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BAvatar,
    BLink,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
    GoalsModel,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      isAddRecordActive: true,
      canEditGoals: true,
      competitions: [],
      competition: useJwt.getContent(),
      editGoals: {
        ID: 0,
        compid: 0,
        description: '',
        competition_KPI_ID: 0,
        floatvalue: 0,
        goal_type: '',
        subject: '',
        src: '',
        reached_datetime: '',
      },
    }
  },
  created() {
    this.fetchCompetitions()
    this.changeCompetitionClick()
  },
  setup() {
    const { APP_STORE_MODULE } = constants
    const {
      fetchGoals,
      resolveGoals,
      goalsTableColumns,
      perPage,
      currentPage,
      totalGoals,
      goals,
      habits,
      behaviours,
      kpis,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refGoalsListTable,
      statusFilter,
      refetchData,
    } = useGoalsList()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, gameStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })

    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      fetchGoals,
      resolveGoals,
      goalsTableColumns,
      goals,
      habits,
      behaviours,
      kpis,
      perPage,
      currentPage,
      totalGoals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refGoalsListTable,
      statusFilter,
      refetchData,
      avatarText,
      resolveUserStatusVariant,
      successMessage,
      errorMessage,
    }
  },
  methods: {
    fetchCompetitions() {
      store.dispatch('app-game/fetchAll').then(response => {
        this.competitions = response.data
        if (router.currentRoute.params.id) {
          this.competition = this.competitions.find(i => i.encrypt_id === router.currentRoute.params.id)
          this.fetchGoals(this.competition.encrypt_id)
          this.canEditGoals = canEditDeleteGame(this.competition)
          this.changeCompetitionClick()
        }
      }).catch(() => {
        this.errorMessage('There was an issue with fetching the game list')
      })
    },
    removeGoals(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.description}  ${this.$i18n.t('KPI_GOAL')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (data) {
              store.dispatch('app-game/deleteGoals',
                data.id)
                .then(response => {
                  if (response.data.status === 'success') {
                    this.successMessage(this.$i18n.t('MESSAGE.GOALS_DELETED'))
                    this.fetchGoals(this.competition.encrypt_id)
                  }
                })
                .catch(() => {
                  this.errorMessage(this.$i18n.t('MESSAGE.GOALS_EXIST'))
                })
            }
          }
        })
    },
    changeCompetitionClick() {
      if (this.competition.encrypt_id) {
        this.isAddRecordActive = false
        this.fetchGoals(this.competition.encrypt_id)
        useJwt.setContent(this.competition)
        this.canEditGoals = canEditDeleteGame(this.competition)
      }
    },
    openGoalsModel(data) {
      if (data) {
        this.editGoals = { ...data }
      }
      if (this.canEditGoals) {
        this.$bvModal.show('goals-modal')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
